<script>
import Layout from "../../layouts/main";
import * as moment from "moment";
import draggable from 'vuedraggable';
import {
  FETCH_TRANSACTIONS,
  FETCH_CHANNEL_LIST,
  EXPORT_TRANSACTIONS_INTO_EXCEL,
  FETCH_MERCHANTS_LIST,
  FETCH_CHANNEL_LIST_FOR_RECON,
  FETCH_COLUMN_LIST,
  UPDATE_COLUMN_LIST,
  RESET_COLUMN_LIST
} from "@/state/actions.type";
import Multiselect from 'vue-multiselect';
import DownloadBtn from '../../../components/download-btn.vue';
import PerPage from '../../../components/per-page.vue';
import SearchBy from '../../../components/search-by.vue';
import Filters from '../../../components/filters.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  components: {
    Layout,
    Multiselect,
    DownloadBtn,
    PerPage,
    SearchBy,
    Filters,
    draggable
  },
  data() {
    return {
      // TODO: Fetch channel and method data by APIs
      paymentMethodList:['CARD','WALLET','BNPL','COD','NET_BANKING','BANK_TRANSFER','INSTALLMENT','QR_CODE','CRYPTO', 'SUBSCRIPTION_CARD', 'SUBSCRIPTION_DIRECT_DEBIT', 'ALL'],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      searchBy: {
        key: "Merchant Order Ref",
        value: ""
      },
      // searchByOptions: ['Merchant Order Ref', 'Channel Order Ref', 'PortOne Order Ref'],
      filters: {
        status:[],
        pmt_channel:[],
        pmt_method:[],
        refund_status: [],
        currency: []
      },
      fromDate: "",
      toDate: "",
      sorting_data: {
        "sort_by_key": "",
        "order": ""
      },
      merchant_uuids:[],
      fields: [],
      // isFilterApplied: false,
      refundOptions: [
        { key: "Initiated", value: "Refund Created" },
        { key: "PartialProcessed", value: "Partial Refund Successful" },
        { key: "Processed", value: "Refund Successful" },
        { key: "Failed", value: "Refund Failed" },
      ],
      currencyOptions: ["VND", "THB", "SGD", "IDR", "MYR", "EUR", "AUD", "PHP", "TWD", "JPY", "KRW", "HKD", "USD", "INR"],
      all_columns_data: [],
      configured_columns_data: [],
      searchColumn: ""
    };
  },

  filters: {
    paymentStatus(row) {
      let status;
      switch (row.value) {
        case 'Initiated':
          status = 'Refund Created';
          break;
        case 'Failed':
          status = 'Refund Failed';
          break;
        case 'PartialProcessed':
          status = 'Partial Refund Successful';
          break;
        case 'Processed':
          status = 'Refund Successful';
          break;
        default:
          status = row.value;
          break;
      }
      return status;
    },
    date(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
      return 'at ' + moment(value).format("hh:mm A");
    },
  },

  created() {
    this.fetchColumns();

    this.$store.dispatch(`transactions/${FETCH_CHANNEL_LIST}`);
    this.$store.dispatch(`reconciliation/${FETCH_CHANNEL_LIST_FOR_RECON}`,{fileType:"transaction",pageType:"transaction"})
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      });
    }
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    paymentsDataFromStore() {
      return this.$store.state.transactions.content;
    },

    paymentChannelList() {
      return this.$store.state.transactions.paymentChannelList;
    },

    paymentChannelListRecon() {
      return this.$store.state.reconciliation.paymentChannelList;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    isFetchingTransactions() {
      return this.$store.state.transactions.isFetchingData;
    },

    rows() {
      return this.$store.state.transactions.total_elements;
    },

    title() {
      return this.$t('views.payments.transactions.payments');
    },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
  },

  methods: {
    fetchColumns() {
      this.$store.dispatch(`transactions/${FETCH_COLUMN_LIST}`).then((response) => {
        if(response.all_columns_data) {
          this.all_columns_data = response.all_columns_data;
        }

        if(response.configured_columns_data) {
          this.configured_columns_data = response.configured_columns_data.sort((a, b) => {
            return a.sort_order - b.sort_order;
          });

          this.setTableFields();
        }
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      });
    },

    setTableFields() {
      this.fields = this.configured_columns_data.map(el => {
        return {
          key: el.key,
          label: el.new_label,
          sortable: ['created_at', 'customer_name', 'status', 'amount', 'status_code', 'channel_name'].includes(el.key)
        }
      });

      this.fields.push({ key: "show_details", label: '', stickyColumn: true });
    },

    resetAdvanceFilters() {
      this.filters = {
        status:[],
        pmt_channel:[],
        pmt_method:[],
        refund_status: [],
        currency: []
      };
      this.merchant_uuids = []
      // if(this.isFilterApplied) {
      this.fetchTransactions();
      //   this.isFilterApplied = false;
      // }
    },

    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'transaction-filter-collapse');
    },

    openTransactionDetailsModal(order_ref, submerchant_key) {
      this.$router.push({path: '/transactions/payments/payment-details', query: { order_ref: order_ref, key: this.isMasterMerchant ? submerchant_key : "" }});
    },

    openRefundsDetailsModal(orderRef, submerchant_key) {
      this.$router.push({path: '/transactions/payments/payment-details/refund', query: { order_ref: orderRef, key: this.isMasterMerchant ? submerchant_key : "" }});
    },

    exportTransactionIntoExcel() {
      var fromDate = ''
      var toDate = ''
      if(this.fromDate) {
        fromDate = moment(this.fromDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        fromDate = moment('2018-01-01T00:00:00.000').format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if(this.toDate) {
        toDate = moment(this.toDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        toDate = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      }
      this.$store.dispatch(`transactions/${EXPORT_TRANSACTIONS_INTO_EXCEL}`, { filters: this.filters, sorting_data:this.sorting_data, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, fromDate, toDate });
      this.showmodal = false;
    },

    // filterApplied() {
    //   return Boolean((this.merchant_uuids && this.merchant_uuids.length) || (this.filters.pmt_channel && this.filters.pmt_channel.length) || (this.filters.pmt_method && this.filters.pmt_method.length) || (this.filters.status && this.filters.status.length));
    // },

    sortTransactions(item) {

      this.sorting_data.sort_by_key = item.sortBy;

      if(item.sortDesc) {
        this.sorting_data.order = "DESC";
      } else {
        this.sorting_data.order = "ASC";
      }

      this.fetchTransactions();
    },

    fetchTransactions() {
      // if(this.filterApplied()) {
      //   this.isFilterApplied = true;
      // } else {
      //   this.isFilterApplied = false;
      // }

      if(this.searchBy.value) {
        this.filters.vector_order_ref = this.searchBy.value.trim()
      } else {
        delete this.filters.vector_order_ref;
      }

      const filters = cloneDeep(this.filters);
      if(filters.refund_status.length > 0) {
        filters.refund_status = filters.refund_status.map(function (item) {
          return item.key;
        });
      } else {
        filters.refund_status = [];
      }

      const payload = {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: filters,
        sorting_data: this.sorting_data
      };

      if(this.fromDate) {
        payload.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.from = moment('2018-01-01T00:00:00.000').format("YYYY-MM-DDTHH:mm:ssZ");
      }

      if(this.toDate) {
        payload.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').format("YYYY-MM-DDTHH:mm:ssZ");
      } else {
        payload.to = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      }


      if(this.isMasterMerchant) {
        payload.merchant_keys = this.merchant_uuids.map(function (item) {
          return item.key;
        });
      }

      this.$store.dispatch(`transactions/${FETCH_TRANSACTIONS}`, {
        payload: payload,
        isMasterMerchant: this.isMasterMerchant,
      });
    },
    dateChanged(value) {
      this.fromDate = value[0];
      this.toDate = value[1];

      this.fetchTransactions();
    },
    toggleEditColumn() {
      this.$root.$emit('bv::toggle::collapse', 'edit-column');

    },
    resetEditColumn() {
      this.fetchColumns();
      this.fetchTransactions();
    },
    toggleEditColumnName(key) {
      this.configured_columns_data = this.configured_columns_data.map(el => {
        if(el.key === key) {
          if(!el.is_edit) {
            this.$refs[`col_${key}`][0].focus();
          }
          el.is_edit = el.is_edit ? false : true;
        } else {
          el.is_edit = false;
        }
        return el;
      });
    },
    toggleColumnSelection(key) {
      let index, column;
      this.configured_columns_data.map((el, i) => {
        if (el.key === key) {
          index = i;
          column = el;
        }
      });

      if(column) {
        if(!column.is_fixed && index) {
          this.configured_columns_data.splice(index, 1);
        }
      } else {
        this.all_columns_data.map((el) => {
          if (el.key === key && el.is_selected) {
            el.sort_order = this.configured_columns_data.length + 1;
            this.configured_columns_data.push(el);
          }
        });
      }
    },

    toggleColumnSelection2(key) {
      let index, column;
      this.all_columns_data.map((el, i) => {
        if (el.key === key) {
          index = i;
          column = el;
        }
      });

      if(index && column && !column.is_fixed) {
        this.all_columns_data[index].is_selected = !this.all_columns_data[index].is_selected;
      }

      this.toggleColumnSelection(key);
    },

    clearAllSelected() {
      this.all_columns_data = this.all_columns_data.map((el) => {
        if (!el.is_fixed && el.is_selected) {
          el.is_selected = false;
        }
        return el;
      });

      this.configured_columns_data = this.configured_columns_data.filter(el => el.is_fixed);
    },

    saveSelectedColumns() {
      this.configured_columns_data = this.configured_columns_data.map((el, index) => {
        el.sort_order = index + 1;
        delete el.is_edit;
        return el;
      });

      this.$store.dispatch(`transactions/${UPDATE_COLUMN_LIST}`, {
        configured_columns_data: this.configured_columns_data
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      })
      .finally(this.toggleEditColumn);
    },

    resetColumns() {
      this.columns = null;
      this.$store.dispatch(`transactions/${RESET_COLUMN_LIST}`)
      .then((response) => {
        this.columns = cloneDeep(response);
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        this.$notify({
          type: "error",
          text: err.message,
          closeOnClick: true,
        });
      })
      .finally(this.toggleEditColumn);
    },
    resetLabel(col) {
      col.new_label = col.default_label;
      col.is_edit = false;
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "Copied Successfully",
        closeOnClick: true,
      });
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <filters class="mb-3" @date-change="dateChanged" @toggle-filter="toggleFilter" @toggle-edit-column="toggleEditColumn" showEditColumn />

    <b-collapse id="transaction-filter-collapse" accordion="my-accordion" @hidden="resetAdvanceFilters" @show="resetAdvanceFilters" style="padding: 25px;" class="common-border bg-white">
      <div v-if="isMasterMerchant" class="mb-4">
        <label class="">{{ $t('common.merchants') }}</label>
        <multiselect
          v-model="merchant_uuids"
          :options="merchantListDataFromStore"
          :placeholder="$t('common.search_merchants')"
          track-by="email_id"
          label="email_id"
          :searchable="true"
          :multiple="true"
        ></multiselect>
      </div>

      <div class="mb-4">
        <label class="">{{ $t('views.payments.transactions.payment_channels') }}</label>
        <multiselect
            v-model="filters.pmt_channel"
            :options="paymentChannelList"
            :placeholder="$t('common.search_payment_channels')"
            :searchable="true"
            :multiple="true"
          ></multiselect>
      </div>

      <div class="mb-4">
        <label>{{ $t('views.payment_channels.payment_methods') }}</label>
        <multiselect
          v-model="filters.pmt_method"
          :options="paymentMethodList"
          :placeholder="$t('common.search_payment_methods')"
          :searchable="true"
          :multiple="true"
        ></multiselect>
      </div>

      <div class="row">
        <div class="col-md-4 mb-4">
          <label>currency</label>
          <multiselect
            v-model="filters.currency"
            :options="currencyOptions"
            placeholder="Search Currency"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="col-md-4 mb-4">
          <label>Refund Status</label>
          <multiselect
            v-model="filters.refund_status"
            :options="refundOptions"
            placeholder="Search Refund Status"
            :searchable="true"
            :multiple="true"
            track-by="key"
            label="value"
          ></multiselect>
        </div>
        <div class="col-md-4 mb-4">
          <label>Transaction Status</label>
          <multiselect
            v-model="filters.status"
            :options="['Success', 'Initiated','Failed', 'Pending', 'Processing', 'In_process', 'Expired', 'Authorized', 'Error', 'Voided', 'Under Review']"
            :placeholder="$t('common.search_transaction_status')"
            :searchable="true"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
        <div>
          <b-btn variant="primary" @click="toggleFilter" class="mr-2 bg-white text-dark cancel">Cancel</b-btn>
          <b-btn variant="primary" @click="fetchTransactions" class="btn-padding">Apply</b-btn>
        </div>
      </div>
    </b-collapse>

    <b-collapse id="edit-column" accordion="my-accordion" @hidden="resetEditColumn" @show="resetEditColumn">
      <div class="common-border bg-white" style="padding: 25px;">
        <p class="mb-0" style="padding-bottom: 8px;">Manage Columns</p>
        <p class="mb-0 text-black-50" style="font-size: 14px;">Select the column that you’d like to see on the table.</p>

        <div class="d-flex" style="padding-top: 35px;">
          <div class="border-right w-50" style="border-color: #DCDAD5 !important;">
            <p class="mb-0" style="padding-bottom: 25px;">Column Options</p>

            <!-- Search Column -->
            <b-input-group class="common-border" style="padding: 4px 16px; margin-right: 47px; margin-bottom: 35px; width: unset; height: 44px;font-size: 14px;">
              <template #prepend>
                  <img class="border-0 d-flex my-auto" src="@/assets/images/search.svg" alt="search"/>
              </template>
              <b-input placeholder="Search..." class="border-0" v-model="searchColumn" />
            </b-input-group>

            <div class="row m-3 overflow-auto" style="max-height: 40vh;">
              <b-form-checkbox
                v-for="(col, index) in all_columns_data"
                :key="index"
                v-show="!searchColumn || col.new_label.toLowerCase().startsWith(searchColumn.toLowerCase())"
                @change="toggleColumnSelection(col.key)"
                v-model="all_columns_data[index].is_selected"
                :unchecked-value="false"
                class="col-lg-6 custom-column"
                :disabled="col.is_fixed"
                style="padding-bottom: 29px;"
              >
                {{ col.new_label }}
              </b-form-checkbox>
            </div>
          </div>

          <div class="w-50" style="padding-left: 25px;">
            <div class="d-flex justify-content-between" style="padding-bottom: 25px;">
              <p class="mb-0">Selected Columns <span class="badge text-chai font-16px" style="border-radius: 8px; background: rgba(252, 107, 45, 0.10);">{{ configured_columns_data.length }}</span></p>
              <b-btn variant="link" @click="clearAllSelected" class="text-chai p-0">Clear all</b-btn>
            </div>

            <div class="overflow-auto" style="max-height: 50vh;">

              <!-- Fixed Columns -->
              <div
                v-for="(col, index) in configured_columns_data"
                :key="index"
                v-show="col.is_fixed"
              >
                <div
                  v-show="col.is_selected"
                  class="d-flex justify-content-between common-border edit-start common-bg-30"
                  style="margin-right: 22px; margin-bottom: 15px; height: 44px; padding: 4px 16px;"
                >
                  <div class="d-flex w-75" style="gap: 10px;">
                    <div class="d-flex" style="width: 10%;">
                    </div>
                    <b-input style="font-size: 14px;" type="text" :ref="`col_${col.key}`" v-model="configured_columns_data[index].new_label" @keypress.enter="toggleEditColumnName(col.key)" class="w-100 my-auto border-0 bg-transparent" :readonly="!col.is_edit" :disabled="!col.is_edit" />
                  </div>

                  <!-- show-on-hover -->
                  <div class="w-25 d-flex justify-content-end" style="gap: 10px;">
                    <img v-if="col.new_label !== col.default_label" class="cursor-pointer my-auto p-0 show-on-hover" @click="resetLabel(col)" src="@/assets/images/rotate-left.svg" alt="reset" />
                    <img v-if="!col.is_edit" class="cursor-pointer my-auto p-0 show-on-hover" @click="toggleEditColumnName(col.key)" src="@/assets/images/edit.svg" alt="edit" />
                    <!-- <span v-show="col.is_edit" class="btn my-2" @click="toggleEditColumnName(col.key)">Save</span> -->

                    <img src="@/assets/images/lock.svg" alt="locked" style="padding: 0.47rem 0.75rem;" class="my-auto" />
                  </div>
                </div>
              </div>

              <!-- Dragable Columns -->
              <draggable :list="configured_columns_data" class="list-group" handle=".handle">
                <div
                  v-for="(col, index) in configured_columns_data"
                  :key="index"
                >
                  <div
                    v-if="!col.is_fixed"
                    v-show="col.is_selected"
                    class="d-flex justify-content-between common-border edit-start bg-white"
                    style="margin-right: 22px; margin-bottom: 15px; height: 44px; padding: 4px 16px;"
                  >
                    <div class="d-flex w-75" style="gap: 10px;">
                      <div class="d-flex" style="width: 10%;">
                        <img src="@/assets/images/drag_icon.svg" alt="drag" class="handle p-0 my-auto" height="16"/>
                      </div>
                      <b-input style="font-size: 14px;" type="text" :ref="`col_${col.key}`" v-model="configured_columns_data[index].new_label" @keypress.enter="toggleEditColumnName(col.key)" class="w-100 my-auto border-0 bg-transparent" :readonly="!col.is_edit" :disabled="!col.is_edit" />
                    </div>

                    <!-- show-on-hover -->
                    <div class="w-25 d-flex justify-content-end" style="gap: 10px;">
                      <img v-if="col.new_label !== col.default_label" class="cursor-pointer my-auto p-0 show-on-hover" @click="resetLabel(col)" src="@/assets/images/rotate-left.svg" alt="reset" />
                      <img v-if="!col.is_edit" class="cursor-pointer my-auto p-0 show-on-hover" @click="toggleEditColumnName(col.key)" src="@/assets/images/edit.svg" alt="edit" />
                      <!-- <span v-show="col.is_edit" class="btn my-2" @click="toggleEditColumnName(col.key)">Save</span> -->
  
                      <img class="cursor-pointer my-auto p-0 show-on-hover" @click="toggleColumnSelection2(col.key)" src="@/assets/images/trash.svg" alt="trash" />
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between" style="padding-top: 35px; padding-bottom: 10px;">
          <b-btn variant="link" @click="resetColumns" class="text-chai font-16px">Reset to Default</b-btn>
          <div class="d-flex" style="gap: 15px;">
            <b-btn variant="white" @click="toggleEditColumn" class="cancel">Cancel</b-btn>
            <b-btn variant="primary" @click="saveSelectedColumns" class="btn-padding">Save</b-btn>
          </div>
        </div>
      </div>
    </b-collapse>

    <div class="d-flex justify-content-between my-3">
      <!-- :options="searchByOptions" -->
      <search-by v-model="searchBy" @search-by-clicked="fetchTransactions" :hideSearchButton="true" />
      <download-btn @download-button-clicked="exportTransactionIntoExcel()" />
    </div>

    <b-table
      class="table-custom"
      :items="paymentsDataFromStore"
      :fields="fields"
      responsive="sm"
      @sort-changed="sortTransactions"
      sticky-header="50vh"
      show-empty
      no-local-sorting
      :busy="isFetchingTransactions"
      :empty-text="$t('common.no-records')"
      hover
    >
      <template #table-busy>
        <b-spinner class="align-middle" :small="true"></b-spinner>
      </template>
      <template v-slot:head()="data">
        <span>
          {{ $t(data.label) }}
          <img v-show="data.field.sortable && sorting_data.sort_by_key !== data.column" class="ml-2" src="@/assets/images/arrow-3.svg" alt="sort_up">
          <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'ASC'" class="ml-2" src="@/assets/images/sort_asc.svg" alt="sort_asc">
          <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'DESC'" class="ml-2" src="@/assets/images/sort_desc.svg" alt="sort_desc">
        </span>
      </template>

      <template #cell(created_at)="row">
        <p class="table-date">{{ row.value | date }}</p>
        <p class="table-time">{{ row.value | time }}</p>
      </template>

      <template v-slot:cell(merchant_order_ref)="{ value }">
        <p class="m-0 text-chai">{{value}}</p>
      </template>

      <template v-slot:cell(payment_link_ref)="{ value, item }">
        <router-link class="text-chai" :to="`/payment-links-details?pageRef=${value}&key=${isMasterMerchant ? item.merchant_key : ''}`">
          {{ value }}
        </router-link>
      </template>

      <template v-slot:cell(receipt_url)="{ value }">
        <div class="d-flex" style="gap: 20px;">
          <a class="text-chai text-truncate d-inline-block my-auto" style="width: 6rem;" target="_blank" :href="value">
            {{ value }}
          </a>
          <i
            v-show="value"
            v-clipboard:copy="value"
            v-clipboard:success="onCopySuccess"
            v-b-tooltip.hover.right
            :title="$t('common.copy')"
            class="mdi mdi-content-copy btn p-0 my-auto"
          ></i>
        </div>
      </template>

      <template v-slot:cell(payment_page_ref)="{ value, item }">
          <router-link class="text-chai" :to="`/settings/payment-pages-details?pageRef=${value}&key=${isMasterMerchant ? item.merchant_key : ''}`">
            {{ value }}
          </router-link>
      </template>

      <template v-slot:cell(subscription_order_ref)="{ value, item }">
          <router-link class="text-chai" :to="`/subscriptions/details?pageRef=${value}&key=${isMasterMerchant ? item.merchant_key : ''}`">
            {{ value }}
          </router-link>
      </template>

      <template v-slot:cell(channel_order_ref)="{ value }">
        {{value}}
      </template>

      <template v-slot:cell(status)="row">
        <div
          class="badge custom-status"
          :class="{
            'badge-soft-danger': ['Error', 'Failed'].includes(row.value),
            'badge-soft-success': row.value === 'Success',
            'badge-soft-warning': row.value === 'Initiated',
            'badge-soft-secondary': ['Expired', 'Voided'].includes(row.value),
            'badge-orange': ['Pending'].includes(row.value),
            'badge-soft-info': ['Authorized', 'Processing', 'In_process'].includes(row.value),
            'badge-purple': row.value === 'Under Review'
          }"
        >{{ row.value }}</div>
      </template>

      <template v-slot:cell(refund_status)="row">
        <div
          class="badge custom-status"
          :class="{
            'badge-soft-danger': row.value === 'Processed',
            'badge-soft-warning': row.value === 'Initiated',
            'badge-gold': row.value === 'PartialProcessed',
            'badge-crimson-red': row.value === 'Failed'
          }"
        >{{ row | paymentStatus }}</div>
      </template>

      <template v-slot:cell(order_ref)="{ value }">
        <div class="text-left">
          {{value}}
        </div>
      </template>

      <template v-slot:cell(amount)="{ value, item }">
        {{ (value).toLocaleString('en-US', {style : 'currency', currency : item.currency}) }}
      </template>

      <template v-slot:cell(channel_name)="row">
        <div class="badge font-14px">
          <img :src="row.item.channel_logo" alt="user-image" class="mr-1" height="16" />
          <span class="align-middle">{{ row.value }}</span>
        </div>
      </template>
      <template v-slot:cell(routing_enabled)="row">
        <p v-if="row.value">{{ row.item.route_rank }}</p>
        <p v-else>1</p>
      </template>

      <template #cell(show_details)="row">
        <b-dropdown class="show_details" size="sm p-0" variant="white bg-transparent" dropleft>
          <template v-slot:button-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
            </svg>
          </template>
          <b-dropdown-item-button @click="openTransactionDetailsModal(row.item.order_ref, row.item.merchant_key)">{{ $t('views.payments.transactions.btn-transaction-details') }}</b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-refund-user') || userRoles.includes('payment-admin')"
            :disabled="row.item.status != 'Success' || row.item.is_refund_allowed == false"
            @click="openRefundsDetailsModal(row.item.order_ref, row.item.merchant_key)">{{ $t('views.payments.transactions.btn-refund') }}</b-dropdown-item-button>
        </b-dropdown>
      </template>
    </b-table>

    <div class="d-flex justify-content-between flex-wrap" style="gap: 10px;">
      <per-page
        v-model="perPage"
        :currentPage="currentPage"
        :totalRows="rows"
        :tableRowsCount="paymentsDataFromStore.length"
        @input="fetchTransactions"
      />
      <b-pagination
        class="my-auto"
        v-model="currentPage"
        :current-page="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        @input="fetchTransactions"
      ></b-pagination>
    </div>
  </Layout>
</template>

<style>
.swal2-container {
  z-index: 9999;
}
</style>

<style lang="scss" scoped>
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red
}
::v-deep .multiselect__placeholder {
  white-space: nowrap !important;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 600px;
  right: -600px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.cursor-pointer{
  cursor: pointer;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.avatar-custom{
    height: 70px;
    width: 95px;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.file_Upload{
  opacity: 0;
  height: 45px;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.bulk-upload-button{
  text-align: center;
  color: #B3AFAF;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  padding: 12px 10px;
  border: 1px dashed #CBCBCB;
  margin-top: 40px;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.badge-orange {
  color: #FA8234;
  background-color: #FA823414;
}

::v-deep .custom-control-label {
  width: 90%;
  word-wrap: break-word;
}

.common-border {
    border: 1px solid #DCDAD5;
    border-radius: 12px;
}

.show-on-hover {
  display: none !important;
}

.edit-start:hover .show-on-hover {
  display: unset !important;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #FC6B2D !important;
  border-color: #FC6B2D !important;
  background-color: rgba(252, 107, 45, 0.10) !important;
}

.btn-white {
  border: 1px solid #000;
}
   ::v-deep  .show_details ul{
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        transform: translate3d(-232px, 0px, 0px) !important;
        width: 220px;

    }
  ::v-deep   .show_details ul button{
        padding: 12px 25px;
        border-radius: 0px;
    }
::v-deep .custom-column {
  label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px; /* 142.857% */
  }
}
</style>